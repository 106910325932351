import axios from "axios";
import store from "@/stores/index";
import { gtag } from "ga-gtag";

export default {
  namespaced: true,
  state() {
    return {
      locale: "sv",
      isAppLoaded: false,
      // Init show notice false to avoid loading the CookieNotice component if not needed.
      cookieNotice: false,
      cookieConsent: {
        // Analytics: Enables storage, such as cookies (web) or device identifiers (apps), related to analytics, for example, visit duration.
        analytics_storage: false,
        // Advertising: Enables storage, such as cookies (web) or device identifiers (apps), related to advertising.
        ad_storage: false,
        // Advertising: Sets consent for sending user data to Google for online advertising purposes.
        ad_user_data: false,
        // Sets consent for personalized advertising.
        ad_personalization: false,
      },

      languages: [],
      langMenuItems: [],
      settings: [],
      globalSettings: [],
      currencies: [],
      toggleCategoriesSidebar: false,
      toggleSearch: false,
      selectSearchCategory: null,
      keywords: null,
      pageContent: [],
      pages: [],
    };
  },
  getters: {
    getGlobalSetting: (state) => (key) => {
      let setting = state.globalSettings.find((s) => s.key === key);
      return setting?.value;
    },
    getSetting: (state) => (key) => {
      let setting = state.settings.find((s) => s.key === key);
      return setting?.value;
    },
    getPageContent: (state) => (slug) => {
      if (state.pageContent[slug] !== undefined) {
        return state.pageContent[slug];
      }
      return "";
    },
    getCookieAnalysisChoice: (state) => () => {
      store.dispatch("global/refreshCookieNoticeStatus");
      return state.cookieConsent.analytics_storage;
    },
    getCookieMarketingChoice: (state) => () => {
      return state.cookieConsent.ad_storage;
    },
  },
  mutations: {
    setIsAppLoaded(state) {
      state.isAppLoaded = true;
    },
    setCookieConsent(state, { analytics_storage, ad_storage }) {
      localStorage.setItem("cookieAllowAnalysis", analytics_storage);
      localStorage.setItem("cookieAllowMarketing", ad_storage);
      localStorage.setItem("cookieConsentExpire", Date.now() + 365 * 24 * 60 * 60 * 1000);
      state.cookieConsent.analytics_storage = analytics_storage;
      state.cookieConsent.ad_storage = ad_storage;
    },
    updateLocale(state, newLocale) {
      state.locale = newLocale;
    },
    toggleCategoriesSidebar(state) {
      state.toggleCategoriesSidebar = !state.toggleCategoriesSidebar;
      if (!state.toggleCategoriesSidebar) {
        // Have become inactive, try to reset sidebar items.
        store.dispatch("catalog/resetSidebarCategories");
      }
    },
    toggleSearch(state) {
      state.toggleSearch = !state.toggleSearch;
    },
    selectLocaleMenuItem(state, langCode) {
      state.langMenuItems.forEach((lang) => {
        lang.icon = lang.code === langCode ? "pi pi-check" : "";
      });
    },
    setLangMenuItems(state, languages) {
      state.langMenuItems = languages;
    },
    setCurrencies(state, currencies) {
      state.currencies = [];
      currencies.forEach((currency) => {
        state.currencies.push({
          id: currency.id,
          code: currency.currency_code,
          title: currency.currency_title,
        });
      });
    },
    setSettings(state, settings) {
      state.settings = [];
      settings.forEach((setting) => {
        state.settings.push({
          id: setting.id,
          key: setting.key_value,
          value: setting.value,
        });
      });
    },
    setGlobalSettings(state, settings) {
      state.globalSettings = [];
      settings.forEach((setting) => {
        state.globalSettings.push({
          id: setting.id,
          key: setting.name,
          value: setting.value,
        });
      });
    },
    setPageContent(state, { slug, content }) {
      state.pageContent[slug] = content;
    },
    setPages(state, pages) {
      state.pages = pages;
    },
  },
  actions: {
    appLoaded({ commit }) {
      commit("setIsAppLoaded");
    },
    sendGtagEvent({ getters }, { event, eventdata }) {
      // send event to google
      if (getters["getCookieAnalysisChoice"]()) {
        gtag("event", event, eventdata);
      }
    },
    refreshCookieNoticeStatus({ state }) {
      let expire = localStorage.getItem("cookieConsentExpire");
      let consentmissing =
        localStorage.getItem("cookieAllowAnalysis") == null || localStorage.getItem("cookieAllowMarketing") == null;
      state.cookieConsent.analytics_storage = localStorage.getItem("cookieAllowAnalysis") === "true";
      state.cookieConsent.ad_storage = localStorage.getItem("cookieAllowMarketing") === "true";
      // Cookie notice should appear if consent is expired or if all consent is not set.
      state.cookieNotice = expire == null || Date.now() > expire || consentmissing;
    },
    changeLocale({ commit, dispatch }, newLocale) {
      axios.defaults.headers.common["Accept-Language"] = newLocale;
      commit("updateLocale", newLocale);
      commit("selectLocaleMenuItem", newLocale);
      dispatch("getAppSettings");
      store.dispatch("catalog/reloadVehicleTypes");
    },
    async getAppSettings({ state, commit, dispatch }) {
      let params = {
        j: "getAppSettings",
        lang_id: state.locale,
      }

      return axios.get(import.meta.env.VITE_API_URL, { params }).then((response) => {
        commit("setGlobalSettings", response.data.global_settings);
        commit("setCurrencies", response.data.currencies);
        commit("setSettings", response.data.site_settings);

        let langs = [];
        response.data.languages.forEach((lang) => {
          langs.push({
            icon: state.locale === lang.lang_code.toLowerCase() ? "pi pi-check" : "",
            label: lang.lang_title + " (" + lang.lang_code + ")",
            code: lang.lang_code.toLowerCase(),
            command: () => {
              this.locale = lang.lang_code.toLowerCase();
              dispatch("changeLocale", lang.lang_code.toLowerCase());
            },
          });
        });
        commit("setLangMenuItems", langs);
      });
    },
    async getPageContent({ state, commit }, slug) {
      if (state.pageContent[slug] !== undefined) return;
      let params = {
        j: "getPageContent",
        slug: slug,
      };

      return axios.get(import.meta.env.VITE_API_URL, { params }).then((response) => {
        if (!response.data) return;
        commit("setPageContent", { slug, content: response.data });
      });
    },
    async getPages({ state, commit }) {
      if (state.pages.length > 0) return;

      let params = {
        j: "getPages",
      };

      return axios.get(import.meta.env.VITE_API_URL, { params }).then((response) => {
        if (!Array.isArray(response.data)) return;
        commit("setPages", response.data);
      });
    },
    /** Send POST request to API backend */
    async postToApi(_, { apiFunction, data }) {
      let params = {
        j: apiFunction,
      };

      return axios.post(import.meta.env.VITE_API_URL, data, { params });
    },

    selectSearchCategory({ state }, value) {
      state.selectSearchCategory = value;
    },
    keywords({ state }, value) {
      state.keywords = value;
    },
  },
};
