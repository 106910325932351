<template>
  <header>
    <div class="wrap">
      <div class="logo">
        <router-link to="/">
          <img src="/src/assets/auto-trade.svg" alt="Autoelectrade" />
        </router-link>
      </div>

      <Button
        type="button"
        class="p-button-outlined button-category"
        :aria-label="t('categories')"
        @click="$store.commit('global/toggleCategoriesSidebar')"
      >
        <em class="pi pi-bars" />
        <span class="ml-2">{{ t("categories") }}</span>
      </Button>

      <CategoriesSidebar v-if="this.$store.state.global.toggleCategoriesSidebar" />

      <div class="search">
        <SearchBox />
      </div>
      <div class="menu">
        <Menu />
      </div>
    </div>
  </header>
</template>

<script>
import { defineAsyncComponent } from "vue";
import SearchBox from "@/components/molecules/SearchBox.vue";
import Menu from "@/components/molecules/Menu.vue";
import { useI18n } from "vue-i18n";
const CategoriesSidebar = defineAsyncComponent(() => import("@/components/molecules/CategoriesSidebar.vue"));

export default {
  components: {
    CategoriesSidebar,
    SearchBox,
    Menu,
  },
  setup() {
    const { locale, t } = useI18n({ inheritLocale: true, useScope: "global" });
    return { locale, t };
  },
  data() {
    const mql = matchMedia("(min-width: 1400px)");

    return {
      mql,
    };
  },
  /*mounted() {
      this.mql.addEventListener('change', (mql) => {
        if (mql.matches) {
          if (this.$store.state.global.toggleSearch) {
            // this.$store.commit('toggleSearch')
          }
        }
      })
    },*/
};
</script>

<style scoped>
header {
  background-color: #fff;
  margin-bottom: 16px;
}

.wrap {
  max-width: 1400px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.4rem;
  align-items: center;
  position: relative;
}

.logo,
.button-category,
.menu {
  flex-shrink: 0;
}

.logo a {
  color: #000;
  font-weight: 900;
  text-decoration: none;
}

@media (min-width: 1px) {
  .logo img {
    height: 32px;
    width: 90px;
  }
}

@media (min-width: 768px) {
  .logo img {
    height: 40px;
    width: 115px;
  }
}

.button-category {
  color: var(--button-color-dark) !important;
  margin-left: 8px !important;
  padding: 8px !important;
}

.button-category:hover {
  color: var(--button-color-dark-hover) !important;
}

.button-category .pi {
  font-weight: bold;
  font-size: 20px;
}

.button-category span {
  display: none;
}

.button-category.p-button:hover {
  color: var(--button-color-dark-hover);
}

.search {
  flex-grow: 1;
}

@media (min-width: 768px) {
  .button-category {
    margin-left: 16px !important;
  }

  .search {
    padding: 0 8px;
  }

  .logo img {
    height: 40px;
  }
}

@media (min-width: 992px) {
  .button-category {
    padding: 0.5rem 1rem !important;
  }

  .button-category span {
    display: inline-flex;
  }
}

@media (min-width: 1400px) {
  .search {
    padding: 0 16px;
  }
}
</style>
