import axios from 'axios';

export const login = (email: string, pass: string) => {
  const formData = new FormData();
  formData.append("email", email);
  formData.append("pass", pass);
  return axios.post(import.meta.env.VITE_API_URL, formData, { params: { j: "login" } });
};

export const getUserProfile = () => {
  const params = {
    j: "getProfile",
  };

  return axios.get(import.meta.env.VITE_API_URL, { params });
};