import { gtag, initDataLayer, install } from "ga-gtag";

export const setupFacebookPixel = () => {
  // Facebook Pixel initialization
  !function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;
    n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,
    document,'script','https://connect.facebook.net/en_US/fbevents.js');

  const link = document.createElement("link");
  link.href = "https://connect.facebook.net";
  link.rel = "preconnect";
  document.head.appendChild(link);

  /* global fbq */
  fbq("init", "1740491256289643");
  fbq("track", "PageView");
};

export const setupGoogleAnalytics = (cookieAnalytics, cookieMarketing) => {
  initDataLayer();

  gtag("consent", "default", {
    ad_storage: "denied",
    analytics_storage: "denied",
    ad_user_data: "denied",
    ad_personalization: "denied",
    wait_for_update: 500,
  });

  if (cookieMarketing != null || cookieAnalytics != null) {
    install("G-CPMCPMZFHW");

    gtag("consent", "update", {
      ad_storage: cookieMarketing === "true" ? "granted" : "denied",
      analytics_storage: cookieAnalytics === "true" ? "granted" : "denied",
    });

    const link = document.createElement("link");
    link.href = "https://www.googletagmanager.com";
    link.rel = "preconnect";
    document.head.appendChild(link);
  }
};

export const initializeAnalytics = () => {
  const cookieAnalytics = localStorage.getItem("cookieAllowAnalysis");
  const cookieMarketing = localStorage.getItem("cookieAllowMarketing");

  if (cookieMarketing === "true") {
    setupFacebookPixel();
  }

  setupGoogleAnalytics(cookieAnalytics, cookieMarketing);
};