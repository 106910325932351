import Vuex from "vuex";
import global from "./global";
import account from "./account";
import catalog from "./catalog";
import parts from "./parts";
import ad from "./ad";

export default new Vuex.Store({
  // plugins: [createPersistedState()],
  modules: {
    global,
    account,
    catalog,
    parts,
    ad,
  },
});
