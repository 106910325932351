<template>
  <Header></Header>

  <div class="wrap maincontainer">
    <router-view />
  </div>

  <Footer></Footer>

  <Toast position="top-right" />
  <CookieNotice v-if="cookieNotice" />
</template>

<script setup>
import { computed, onMounted, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useHead } from '@unhead/vue'
import { defineAsyncComponent } from 'vue'
import { useStore } from 'vuex'
import axios from 'axios'
import { i18n, configureLocale } from "@/plugins/i18n";
import Header from '@/components/organisms/Header.vue'
import Footer from '@/components/organisms/Footer.vue'

// Setup i18n
const { locale, t } = useI18n({ inheritLocale: true, useScope: 'global' })
const Toast = defineAsyncComponent(() => import('primevue/toast'))

// Components
const CookieNotice = defineAsyncComponent(() => import('@/components/molecules/CookieNotice.vue'))
const store = useStore()

// Setup head
useHead({
  title: 'Autoelectrade | Köp och sälj, delar och verktyg - till alla slags fordon',
  link: [],
})

// Computed properties
const globalLocale = computed(() => store.state.global.locale)
const loginStatus = computed(() => store.state.account.loginStatus)
const cookieNotice = computed(() => store.state.global.cookieNotice)

// Watchers
watch(globalLocale, (newValue) => {
  const newLocale = newValue;
  configureLocale(i18n, newLocale);
});

watch(loginStatus, (newValue) => {
  if (newValue === true) {
    store.dispatch('account/loadProfile')
  }
});

// Mounted logic
onMounted(() => {
  locale.value = globalLocale.value
  axios.defaults.headers.common['Accept-Language'] = globalLocale.value

  store.dispatch('global/refreshCookieNoticeStatus')
  Promise.all([store.dispatch('global/getPages')])

  // Commented original promises
  // Promise.all([
  //   store.dispatch('global/getAppSettings'),
  //   store.dispatch('global/getPages'),
  //   store.dispatch('catalog/loadVehicleTypes'),
  //   store.dispatch('catalog/loadSubCategories', 0),
  // ])
});
</script>

<style>
:root {
  --red: #e52727;
  --green: #397739;
  --green2: #49b975;

  margin: 0;
  padding: 0;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--surface-100);
  font-family: "Roboto", sans-serif;
  font-size: 1.16rem;
  margin: 0;
  padding: 0;
}

.wrap {
  min-width: 300px;
  max-width: 1400px;
  margin: 0 auto 0 auto;
}

.maincontainer {
  width: 100%;
  min-width: 300px;
  min-height: 600px;
}

@media (min-width: 768px) {
  .maincontainer {
    min-width: 750px;
    min-height: 850px;
  }
}
</style>
