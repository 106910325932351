import { createI18n } from "vue-i18n";
import { nextTick } from 'vue'

// import all the json files

export const i18n = createI18n({
  locale: import.meta.env.VUE_APP_I18N_LOCALE || "sv",
  fallbackLocale: import.meta.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
  legacy: false,
  globalInjection: true,
  runtimeOnly: false,
});

export function setI18nLanguage(i18n, locale) {
  if (i18n.mode === 'legacy') {
    i18n.global.locale = locale
  } else {
    i18n.global.locale.value = locale
  }
  /**
   * NOTE:
   * If you need to specify the language setting for headers, such as the `fetch` API, set it here.
   * The following is an example for axios.
   *
   * axios.defaults.headers.common['Accept-Language'] = locale
   */
  document.querySelector('html').setAttribute('lang', locale)
}

export function configureLocale(i18n, locale) {
  // load locale messages
  loadLocaleMessages(i18n, locale).then(() => {
    setI18nLanguage(i18n, locale);
  });
}

export async function loadLocaleMessages(i18n, locale) {
  // load locale messages with dynamic import
  const messages = await import(`@/locales/global/${locale}.json`)

  // set locale and locale message
  i18n.global.setLocaleMessage(locale, messages.default)

  return nextTick()
}
