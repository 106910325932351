import axios from "axios";
import store from "@/stores/index";

export default {
  namespaced: true,
  state() {
    return {
      // ----------------------------------
      // category_id --> category
      categoryMap: {},
      // slug --> category
      categorySlugMap: {},
      // parent_id --> subcategories
      categoryTree: new Object(),

      categorySelection: [],
      // vehicleTypeId --> vehicleType
      vehicleTypeMap: new Object(),
      // manufacturerId --> models
      modelsMap: new Object(),

      // ----------------------------------
      vehicleTypes: [],
      categories: [],
      vehicleManufacturers: new Object(),
      // manufacturer id --> manufacturer
      subCategoriesLoading: new Object(),
      loadingVehicleTypes: false,
      loadingManufacturers: false,
      loadingAllManufacturers: true,
      loadingCategories: false,
      loadingSubCategories: false,
      loadingModels: false,
      selectCategory: 0,
      // Hero variables, selected items.
      keywords: null,
      selectHeroManufacturer: null,
      selectHeroModel: null,
      isSearching: false,
      // filter variables used to temporary store filtered category selection
      // in HeroSearch, Category sidebar and other components.
      // Variables are used to corrrectly cache ads count and keep track of current
      // selected model an manufacturer.
      filterManufacturer: -1,
      filterModel: -1,
      filterCategory: -1,
      // Helper variable to keep track of currently selected category.
      // Need to avoid multiple executions of onClick function for both expand/decrease menu.
      sidebarCategory: null,

      // Cache of adscount based on category and manufacturer/model.
      adscounts: new Object(),
      // Cache if adscount have been loaded for a certain manufacturer and/or model
      adscountLoadedManufacturer: new Object(),
      adscountLoadedModel: new Object(),

      // Items in sidebar. Cache to be able to reset when needed.
      sidebarMenuItems: [],
    };
  },
  mutations: {
    toggleSearch(state) {
      state.toggleSearch = !state.toggleSearch;
    },

    filterManufacturer(state, value) {
      state.filterManufacturer = value;
    },

    filterModel(state, value) {
      state.filterModel = value;
    },

    loadModelsAdCounts(state, { category_id, manufacturer_id, counts }) {
      Object.keys(counts).forEach((k) => {
        state.adscounts[`${category_id}_${manufacturer_id}_${k}`] = parseInt(counts[k]);
      });
      state.adscountLoadedModel[`${category_id}_${manufacturer_id}`] = 1;
    },

    loadManufactuersAdCounts(state, { category_id, counts }) {
      Object.keys(counts).forEach((k) => {
        state.adscounts[`${category_id}_${k}_0`] = parseInt(counts[k]);
      });
      state.adscountLoadedManufacturer[`${category_id}}`] = 1;
    },

    loadAdsCounts(state, { adsfilter, counts }) {
      // Save adscounts for filter
      let suffix = "";
      if (!adsfilter) {
        adsfilter = new Object();
      }
      suffix += (adsfilter.manufacturer ? adsfilter.manufacturer : 0) + "_";
      suffix += (adsfilter.model ? adsfilter.model : 0) + "_";
      suffix = suffix.slice(0, -1); // Strip last _
      Object.keys(counts).forEach((k) => {
        state.adscounts[`${k}_${suffix}`] = parseInt(counts[k]);
      });
    },

    loadVehicleTypes(state, vehicleTypes) {
      // https://www.primefaces.org/primevue/showcase/#/menumodel
      state.vehicleTypes.length = 0;
      vehicleTypes.forEach((el) => {
        el.manufacturers = [{ title: "" }];
        state.vehicleTypeMap[el.id] = el;
        state.vehicleTypes.push(el);
      });
    },
    loadManufacturers(state, { vehicleType, manufacturers }) {
      if (vehicleType === parseInt(vehicleType, 10)) {
        // Vehicle type is integer. Retrieve from map.
        vehicleType = state.vehicleTypeMap[vehicleType];
      }

      // Store mapping of vehicleType --> manufacturers.
      if (!state.vehicleManufacturers[vehicleType.id]) {
        state.vehicleManufacturers[vehicleType.id] = [];
      } else {
        state.vehicleManufacturers[vehicleType.id].length = 0;
      }

      // Set manufacturers for vehicle type.
      manufacturers.forEach((itm) => {
        state.vehicleManufacturers[vehicleType.id].push(itm);
      });
    },
    async loadModels(state, { manufacturer, models }) {
      state.modelsMap[manufacturer.id] = models;
    },
    // Reset category cache.
    resetCategoryCache(state) {
      state.categoryTree = {};
      state.categoryMap = {};
      state.categorySlugMap = {};
    },
    // Store categories.
    categories(state, { parent_category, categories }) {
      let depth = parent_category == 0 ? 0 : state.categoryMap[parent_category].depth + 1;
      let cats = [];
      categories.forEach((catobj) => {
        catobj.depth = depth;
        cats.push(catobj);
        this.commit("catalog/category", { catobj });
      });
      // Store in category
      state.categoryTree[parent_category] = cats;
    },
    category(state, { catobj }) {
      if (!(catobj.vehicle_type in state.categorySlugMap)) {
        state.categorySlugMap[catobj.vehicle_type] = Object();
      }
      state.categorySlugMap[catobj.vehicle_type][catobj.slug] = catobj;
      state.categoryMap[catobj.id] = catobj;
    },
  },
  getters: {
    // --------------------------------------------------
    getVehicleTypes: (state) => () => {
      return state.vehicleTypes;
    },
    getVehicleType: (state) => (id) => {
      let vehicleType = state.vehicleTypes.find((obj) => {
        return obj.id == id;
      });
      return vehicleType;
    },
    getManufacturers: (state) => (vehicleType) => {
      return state.vehicleManufacturers[vehicleType];
    },
    getManufacturerById: (state) => (vehicleType, manufacturer_id) => {
      if (!(vehicleType in state.vehicleManufacturers)) {
        return null;
      }
      return state.vehicleManufacturers[vehicleType].find((obj) => {
        return obj.id == manufacturer_id;
      });
    },
    getCategoryModel: (state) => (parent_id) => {
      if (!(parent_id in state.categoryTree)) {
        return [];
      }
      return state.categoryTree[parent_id];
    },
    getCategoryById: (state) => (id) => {
      let cc = state.categoryMap[id];
      return cc;
    },
    getCategoryBySlug: (state) => (vehicletype, slug) => {
      return state.categorySlugMap[vehicletype][slug] || null;
    },
    getModels: (state) => (manufacturer_id) => {
      return state.modelsMap[parseInt(manufacturer_id)];
    },
    getModelById: (state) => (manufacturer_id, model_id) => {
      const mid = parseInt(manufacturer_id);
      if (!(mid in state.modelsMap)) {
        return null;
      }
      const modelId = parseInt(modelId, 10);
      return state.modelsMap[mid].find((obj) => {
        return obj.id == modelId;
      });
    },
  },
  actions: {
    // TEST RESET
    resetSidebarCategories({ state }) {
      for (const c of state.sidebarMenuItems) {
        c.items.length = 0;
      }

      // category_id --> category
      // parent_id --> subcategories
      state.categoryTree = {
        0: state.categoryTree[0],
      };
      state.categorySelection = [];
      state.vehicleManufacturers = new Object();
      state.modelsMap = new Object();
    },

    /**
     * Load ads counts by model for given category, manufacturer.
     * @param {*} Context
     * @param {*} Object with array of category and manufacturer.
     */
    async loadModelsAdCounts({ commit, state }, { category, manufacturer }) {
      if (state.adscountLoadedModel[`${category.id}_${manufacturer.id}`]) {
        return Promise.resolve();
      }
      let params = {
        j: "getModelsAdCounts",
        category: category.id,
        manufacturer: manufacturer.id,
      };
      return axios.get(import.meta.env.VITE_API_URL, { params }).then((response) => {
        commit("loadModelsAdCounts", { category_id: category.id, manufacturer_id: manufacturer.id, counts: response.data });
      });
    },

    /**
     * Load ads counts for given categories based on given root category.
     * @param {*} Context
     * @param {*} Object with array of category and manufacturer.
     */
    async loadManufacturersAdCounts({ commit, state }, { rootCategory }) {
      if (state.adscountLoadedManufacturer[rootCategory.id]) {
        return Promise.resolve();
      }
      let params = {
        j: "getManufacturersAdCounts",
        category: rootCategory.id,
      };
      return axios.get(import.meta.env.VITE_API_URL, { params }).then((response) => {
        commit("loadManufactuersAdCounts", { category_id: rootCategory.id, counts: response.data });
      });
    },
    /**
     * Load ads counts for given categories based on given filter with manufacturer/model.
     * @param {*} Context
     * @param {*} Object with array of categories and adsfilter which will be used when retrieving adscount.
     */
    async loadAdsCounts({ commit }, { adsfilter, categories }) {
      // Get list of category ids
      let cids = categories.map((x) => x.id);
      let params = {
        j: "getCategoriesAdCounts",
        categories: cids.join(","),
      };
      // Add filter to params if supplied.
      if (adsfilter.manufacturer) {
        params.countfilter_manufacturer = adsfilter.manufacturer;
      }
      if (adsfilter.model) {
        params.countfilter_model = adsfilter.model;
      }

      return axios.get(import.meta.env.VITE_API_URL, { params }).then((response) => {
        commit("loadAdsCounts", { adsfilter, counts: response.data });
      });
    },
    /**
     * Loads subcategories for given parent
     * @param {*} Context
     * @param {*} parent_category Parent category of categories to be loaded.
     */
    async loadSubCategories({ dispatch }, parent_category) {
      return await dispatch("getCategoriesByCategory", { parent_category: parent_category, adsfilter: null });
    },

    /**
     * Load categories by slug.
     * @param {*} Context
     * @param {*} Object with vehicle_type and slug which will be used when retrieving adscount
     */
    async loadCategoriesBySlug({ state, commit }, { vehicle_type, slug }) {
      if (slug in state.categorySlugMap[vehicle_type]) {
        // Already loaded
        return Promise.resolve();
      }
      let params = {
        j: "getCategoryBySlug",
        lang_id: store.state.global.locale,
        vehicle_type: vehicle_type,
        slug: slug
      };
      return axios
        .get(import.meta.env.VITE_API_URL, { params })
        .then((response) => {
          let data = response.data;
          commit("category", { catobj: data });
        });
    },

    /**
     * Load subcategories for parent_category. Will also load adscount based on adsfilter and store those in adscount cache.
     * @param {*} Context
     * @param {*} Object with parent_category and adsfilter which will be used when retrieving adscount
     */
    async loadSubCategoriesWithFilter({ state, dispatch }, { parent_category, adsfilter }) {
      if (parent_category in state.categoryTree && state.categoryTree[parent_category]) {
        // No need to reload categories already loaded.
        if (adsfilter) {
          return dispatch("loadAdsCounts", { adsfilter, categories: state.categoryTree[parent_category] });
        }
        return Promise.resolve();
      }
      return dispatch("getCategoriesByCategory", { parent_category: parent_category, adsfilter: adsfilter });
    },
    /**
     * Load categories and vehicle types required for app to work.
     * @param {*} Context
     */
    loadInitializationData({ commit }) {
        let params = {
          j: "getCategoryInitData",
          lang_id: store.state.global.locale,
        };
        return axios
          .get(import.meta.env.VITE_API_URL, { params })
          .then((response) => {
            let data = response.data;
            commit("categories", { parent_category: 0, categories: data["root_categories"] });
            commit("loadVehicleTypes", data["vehicle_types"]);
          });
    },
    /**
     * Retrieves subcategories of provided parent and stores them in cache.
     */
    getCategoriesByCategory({ state, commit }, { parent_category, adsfilter }) {
      if (parent_category in state.subCategoriesLoading || parent_category in state.categoryTree) {
        return Promise.resolve();
      }
      state.subCategoriesLoading[parent_category] = true;

      let params = {
        j: "getCategoriesByCategory",
        category_id: parent_category,
        lang_id: store.state.global.locale,
      };

      if (adsfilter) {
        if (adsfilter.manufacturer) {
          params.countfilter_manufacturer = adsfilter.manufacturer;
        }
        if (adsfilter.model) {
          params.countfilter_model = adsfilter.model;
        }
      }

      return axios
        .get(import.meta.env.VITE_API_URL, { params })
        .then((response) => {
          if (!Array.isArray(response.data)) return;
          let categories = response.data;

          commit("categories", { parent_category, categories });
          // If categories was retrieved with adsfilter for adscount, commit those aswell.
          if (adsfilter) {
            let counts = new Object();
            for (const c of categories) {
              counts[c.id] = c["ads_count"];
            }
            commit("loadAdsCounts", { adsfilter, counts });
          }
        })
        .finally(() => {
          delete state.subCategoriesLoading[parent_category];
        });
    },
    // --------------------------------------------------
    async loadVehicleTypes({ state, dispatch }) {
      // Load root categories aswell.
      if (!(0 in state.categoryTree)) {
        await dispatch("loadSubCategories", 0);
      }
      // --------------------------------------------------
      if (state.vehicleTypes.length > 0 && state.vehicleTypes[0].title != "") return;
      return dispatch("reloadVehicleTypes");
    },
    async reloadVehicleTypes({ state, commit }) {
      state.loadingVehicleTypes = true;
      let params = {
        j: "getVehicleTypes",
        lang_id: store.state.global.locale,
      };
      return axios
        .get(import.meta.env.VITE_API_URL, { params })
        .then((response) => {
          if (!Array.isArray(response.data)) {
            return;
          }
          commit("loadVehicleTypes", response.data);
        })
        .finally(() => {
          state.loadingVehicleTypes = false;
        });
    },
    async loadManufacturers({ state, commit }, vehicleType) {
      let vehicleTypeId;
      if (vehicleType === parseInt(vehicleType, 10)) {
        // Vehicle type is integer. Retrieve from map.
        vehicleTypeId = vehicleType;
      } else {
        vehicleTypeId = vehicleType.id
      }
      if (Object.hasOwn(state.vehicleManufacturers, vehicleTypeId)) {
        return vehicleType;
      }
      state.loadingManufacturers = true;

      let params = {
        j: "getManufacturers",
        vehicle_type: vehicleTypeId,
      };

      await axios
        .get(import.meta.env.VITE_API_URL, { params })
        .then((response) => {
          if (!Array.isArray(response.data)) return;
          commit("loadManufacturers", { vehicleType, manufacturers: response.data });
        })
        .finally(() => {
          state.loadingManufacturers = false;
        });
      return vehicleType;
    },
    async loadModels({ state, commit }, { vehicleType, manufacturer }) {
      if (vehicleType === parseInt(vehicleType, 10)) {
        // Vehicle type is integer. Retrieve from map.
        let id = vehicleType;
        vehicleType = state.vehicleTypes.find((obj) => {
          return obj.id == id;
        });
      }
      if (manufacturer === parseInt(manufacturer, 10)) {
        manufacturer = state.vehicleManufacturers[vehicleType.id].find((obj) => {
          return obj.id == manufacturer;
        });
      }
      if (Object.hasOwn(state.modelsMap, manufacturer.id)) {
        return Promise.resolve();
      }
      state.loadingModels = true;

      let params = {
        j: "getModelsByManufacturerId",
        manufacturer_id: manufacturer.id,
        lang_id: store.state.global.locale,
      };

      return axios
        .get(import.meta.env.VITE_API_URL, { params })
        .then((response) => {
          if (!Array.isArray(response.data)) return;
          commit("loadModels", { vehicleType, manufacturer, models: response.data });
        })
        .finally(() => {
          state.loadingModels = false;
        });
    },
    selectHeroManufacturer({ state }, value) {
      state.selectHeroManufacturer = value;
    },
    selectHeroModel({ state }, value) {
      state.selectHeroModel = value;
    },
    selectCategory({ state }, value) {
      state.selectCategory = value;
    },
    keywords({ state }, value) {
      state.keywords = value;
    },
    isSearching({ state }, value) {
      state.isSearching = value;
    },
    async getModels(manufacturer_id) {
      let params = {
        j: "getModelsByManufacturerId",
        manufacturer_id: manufacturer_id,
      };

      return axios.get(import.meta.env.VITE_API_URL, { params }).then((response) => {
        if (!Array.isArray(response.data)) return;
        return response.data;
      });
    },
  },
};
