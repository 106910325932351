<template>
  <footer style="min-height: 60px; width: 100%">
    <div class="wrap">
      <p class="ml-3">&copy; 2024 Autoelectrade</p>
      <div class="footer-menu grid ml-3" style="display: flex; align-items: center; justify-content: center">
        <router-link v-for="(page, index) in pages" :key="index" :to="'/' + page.slug">{{ page.title }}</router-link>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  computed: {
    pages() {
      let pages = [];
      for (const i in this.$store.state.global.pages) {
        let page = this.$store.state.global.pages[i];
        pages.push({ slug: page.slug, title: page.title });
      }
      pages.splice(2, 0, { slug: "cookie-policy", title: "Cookies" });
      return pages;
    },
  },
};
</script>

<style scoped>
footer {
  color: var(--surface-700);
  font-size: 16px;
  background-color: var(--surface-900);
  padding: 24px 0;
  margin-top: 16px;
}

.wrap {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

p {
  margin: 0;
}

.footer-menu a {
  color: var(--surface-500);
  text-decoration: none;
  margin-right: 16px;
}

.footer-menu a:hover {
  color: var(--surface-400);
  text-decoration: underline;
}

@media (max-width: 767px) {
  .footer-menu {
    margin-top: 2px;
  }
}
@media (min-width: 768px) {
  .wrap {
    flex-direction: row;
    justify-content: space-between;
  }

  p {
    margin-left: 8px;
  }
}
</style>
