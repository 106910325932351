<template>
  <span v-if="isSearchEnabled || grid.lg" class="search p-inputgroup">
    <Dropdown
      v-model="selectSearchCategory"
      :options="rootCategories"
      option-label="title"
      :placeholder="t('vehicle_type_dropdown')"
      scroll-height="256px"
    />
    <label for="siteSearch" class="sr-only">{{ t('search') }}</label>
    <InputText
      v-model="keywords"
      type="text"
      :placeholder="t('search')"
      @keyup.enter="search"
      id="siteSearch"
    />
    <Button
      :disabled="isSearchDisabled"
      icon="pi pi-search"
      :loading="isSearching"
      @click="search"
      :aria-label="t('search')"
    />
  </span>
</template>

<script setup lang="ts">
import { ref, computed, watch, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import { useI18n } from "vue-i18n";
import { useGrid } from 'vue-screen'
import Dropdown from "primevue/dropdown";

const store = useStore();
const router = useRouter();
const route = useRoute();
const grid = useGrid('bootstrap')
const { t } = useI18n({ inheritLocale: true, useScope: "global" });

const isSearching = ref(false);
const isSearchDisabled = ref(false);

const isSearchEnabled = computed(() => store.state.global.toggleSearch);
const rootCategories = computed(() => store.getters["catalog/getCategoryModel"](0));

const selectSearchCategory = computed({
  get: () => store.state.global.selectSearchCategory,
  set: (value) => store.dispatch("global/selectSearchCategory", value)
});
const keywords = computed({
  get: () => store.state.global.keywords,
  set: (value) => store.dispatch("global/keywords", value)
});

watch(() => grid.lg, (newValue) => {
  if (newValue && isSearchEnabled.value) {
    store.commit("global/toggleSearch");
  }
});

onMounted(() => {
  if (route.query.keywords) {
    keywords.value = route.query.keywords;
  }
});

const search = () => {
  const query: { keywords?: string } = {};
  let path = "/ads";
  if (selectSearchCategory.value) path += '/' + selectSearchCategory.value.slug;
  if (keywords.value) query.keywords = keywords.value;

  router.push({ path, query });
};
</script>

<style scoped>
.search {
  position: absolute;
  top: 100%;
  left: 0;
}

.p-inputtext {
  background-color: var(--surface-100);
  font-size: 1.16rem;
}

.p-dropdown {
  max-width: 10rem;
}

.p-inputtext:focus {
  background-color: #fff;
}

.sr-only {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

@media (min-width: 768px) {
  .search {
    position: relative;
  }
}
</style>